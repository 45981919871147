<template>
  <div class="w1092">
    <div class="pagenamew">
      <span class="couponn">사용 가능한 쿠폰: <span class="pkc">32</span>장</span>
    </div>
    <div class="board">
      <ul class="boardw first">
        <li class="">쿠폰명</li>
        <li class="">쿠폰금액</li>
        <li class="">보낸회원아이디</li>
        <li class="">처리상태</li>
        <li class="">발급일</li>
        <li class="">만료일</li>
        <li class="">쿠폰사용</li>
      </ul>
      <ul class="boardw">
        <li class="">테스트쿠폰</li>
        <li class="rdc">10,000</li>
        <li class="">eric</li>
        <li class="rdc">사용가능</li>
        <li class="">2021-06-29</li>
        <li class="">2021-07-29</li>
        <li class=""><a class="nbicon orbg">사용하기</a></li>
      </ul>
      <ul class="boardw">
        <li class="">테스트쿠폰</li>
        <li class="rdc">10,000</li>
        <li class="">eric</li>
        <li class="rdc">사용가능</li>
        <li class="">2021-06-29</li>
        <li class="">2021-07-29</li>
        <li class=""><a class="nbicon orbg">사용하기</a></li>
      </ul>
      <ul class="boardw">
        <li class="">테스트쿠폰</li>
        <li class="rdc">10,000</li>
        <li class="">eric</li>
        <li class="rdc">사용가능</li>
        <li class="">2021-06-29</li>
        <li class="">2021-07-29</li>
        <li class=""><a class="nbicon orbg">사용하기</a></li>
      </ul>
      <ul class="boardw">
        <li class="">테스트쿠폰</li>
        <li class="rdc">10,000</li>
        <li class="">eric</li>
        <li class="rdc">사용가능</li>
        <li class="">2021-06-29</li>
        <li class="">2021-07-29</li>
        <li class=""><a class="nbicon orbg">사용하기</a></li>
      </ul>
    </div>

    <div class="boardbtn"></div>
    <div class="boardpage mt27 mb27">
      <pagination :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  @goToPage="onCashListSearch" />
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
export default {
  name: 'couponUse',
  components: {
    Pagination
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
